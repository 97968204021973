<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense :label="$t(positionLabel)" type="text" :hint="$t('requird')" required v-bind="attrs"
                v-on="on"></v-text-field>
        </template>
        <v-card>
            <v-container>
                <my-map></my-map>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
                {{ $t('cancel') }}
            </v-btn>
            <v-btn color="blue" text @click="setData">
                <span> {{ $t('storage') }}</span>
            </v-btn>
        </v-card>
    </v-dialog>
</template>
<script>

import { driver } from '../../../store/drivers'
import { driverRoute } from '../../../store/driverRoutes'
import notify from '../../../helpers/toast-notifications'
import map from './getMap.vue'
export default {
    props: ['positionLabel'],
    components: {
        myMap: map
    },
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        setData() {
            this.$emit('position', this.getPosition)
            this.dialog = false
        }
    },
    computed: {
        getPosition() {
            return localStorage.getItem('position')
        }
    }
}
</script>
