import { State } from "./state";
import { Request } from "../network/request"
import notify from '../helpers/toast-notifications'
import i18n from '../i18n'
import axios from "axios";

export default class authStore {
    mealData = []
    studentCard = {
        id : '',
        firstName: '',
        lastName: '',
        serial: '',
        daily_limit: '',
        balance: '',
        buffet_note: '',
        ordered_meals: '',
        meals: [],
    }
    mealsTable = {
        tableHeaders: [
            'image',
            'id',
            'name',
            'description',
            'status',
            'dayes Avilable in',
            'price',
            'settings'

        ],
        tableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
    mealsOrderTable = {
        tableHeaders: [
            'image',
            'id',
            'name',
            'price',
            'total',
            'display',

        ],
        tableData: [],
        loading: false,
    }
    mealsStudentsTable = {
        tableHeaders: [
            'image',
            'id',
            'name',
            'description',
            'status',
            'dayes Avilable in',
            'price',
            'settings'

        ],
        tableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }



    mealState = new State()
    storeMealState = new State()
    editMealState = new State()
    updateImage = new State()
    deleteMealState = new State()

    orderMealsState = new State()
    getMealsNameState = new State()

    mealStudentCreateState = new State()

    studentBuffetCard = new State()

    buffetbuyState = new State()
    buffetreturnState = new State()





    geatAllMeal(payload) {
        let token = localStorage.getItem('access_token')
        let request = new Request('get', `meal`, null, payload, token)
        this.mealState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.setMealData(value)
                    console.log(value)
                },
                onError: (error) => { console.log(error) }
            });
    }
    getOrderdMeals(params) {
        let token = localStorage.getItem('access_token')
        let request = new Request('get', `/meals-report`, null, params, token)
        this.orderMealsState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.mealsOrderTable.tableData = value
                },
                onError: (error) => { console.log(error) }
            });

    }
    getMealsName(payload) {
        let token = localStorage.getItem('access_token')
        let request = new Request('get', `/meal?name=${payload}`, null, null, token)

        this.getMealsNameState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.mealData = value.meals
                },
                onError: (error) => { console.log(error) }
            });

    }
    async StoreMeal(payload) {
        try {
            let form = new FormData();
        for (let key in payload) {

            if (payload[key]) {
                if (key == 'days') {
                    let days = payload[key]
                    days.forEach(item => {
                        form.append('days[]', item)
                    });
                } else {
                    form.append(`${key}`, payload[key])
                }

            }
        }
        let res = await axios.post(`/meal`, form);
            this.geatAllMeal(1);
            notify(
                    i18n.t('add success'),
                    { timeout: 4000, rtl: true },
                    "success"
                );
           
        } catch (error) {
            
        }
    }
    editMealImage(payload, payload2) {
       try {
        let form = new FormData();
        form.append('image', payload)
        let token = localStorage.getItem('access_token')
        let request = new Request('post', `meal/${payload2}/image`, form, null, token)
        this.editMealState.trigger(async () => {
            return await (await request.send());
        });            
       } catch (error) {
        
       }
    }
    async editMeal(payload, payload2) {
        try {
            

        let res = await axios.post(`/meal/${payload2}`, payload);
     
                this.geatAllMeal(1);
                    notify(
                        i18n.t('edit success'),
                        { timeout: 4000, rtl: true },
                        "success"
                    );
            
        } catch (error) {
            
        }
    }
    deleteMeal(payload) {
        let token = localStorage.getItem('access_token')
        let request = new Request('delete', `meal/${payload}`, null, null, token)
        this.deleteMealState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    this.geatAllMeal(1)
                },
                onError: (error) => { console.log(error) }
            });
    }
    createStudentMeal(payload) {
        let token = localStorage.getItem('access_token')
        let request = new Request('post', `student_meal`, payload, null, token)
        this.mealStudentCreateState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                   
                },
                onError: (error) => { console.log(error) }
            });
    }

    getStudentBuffetCard(payload) {
        let token = localStorage.getItem('access_token')
        let request = new Request('get', `/buffet-card/${payload}`, null, null, token)
        this.studentBuffetCard.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    console.log(value.data)
                    this.setCardData(value.data)

                },
                onError: (error) => { console.log(error) }
            });
    }
    storeBuffetOperation(payload) {
        let token = localStorage.getItem('access_token')
        let request = new Request('post', `/student_meal/cafeteria`, payload, null, token)
        this.buffetbuyState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    notify(
                        i18n.t('add success'),
                        { timeout: 4000, rtl: true },
                        "success"
                    );
                },
                onError: (error) => {
                    console.log(error)
                }
            });
    }

    restoreBuffetOperation(payload) {
        let token = localStorage.getItem('access_token')
        let request = new Request('post', `/student_meal/cafeteria`, payload, null, token)
        this.buffetreturnState.trigger(async () => {
            return await (await request.send());
        },
            {
                onSuccess: (value) => {
                    notify(
                        i18n.t('add success'),
                        { timeout: 4000, rtl: true },
                        "success"
                    );
                },
                onError: (error) => {
                    console.log(error)
                }
            });
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    setMealData(data) {
        this.mealsTable.tableData = data.meals
        this.mealsTable.tableMeta.total = data.meta.total
        this.mealsTable.tableMeta.page = data.meta.current_page

    }
   async setCardData(data) {
        this.studentCard.serial = data.serial
        this.studentCard.balance = data.balance
        this.studentCard.daily_limit = data.daily_limit
        this.studentCard.buffet_note = data.buffet_note
        this.studentCard.ordered_meals = data.ordered_meals
        this.studentCard.id = data.student_id   
        this.studentCard.meals = await data.meals.map((el)=>({
            ...el
        }));
        console.log(this.studentCard.meals);
    }

}
export const meal = new authStore();