import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/Login",
    component: () => import("@/views/dashboard/Login"),
  },
  {
    path: "/",
    component: () => import("@/views/dashboard/Index"),

    children: [
      {
        name: "Welcom Kinan",
        path: "",
        component: () => import("@/views/dashboard/Dashboard"),
      },
      {
        name: "Main Dashboard",
        path: "mainDashboard",
        component: () =>
          import("@/views/dashboard/modules/maindashboard/mainDashboard"),
      },
      {
        name: "Ai Index Report",
        path: "aiIndexReport",
        component: () =>
          import("@/views/dashboard/modules/Ai/report/aiReportsIndex"),
      },
      {
        name: "Ai Dashboard",
        path: "aiDashboardIndex",
        component: () =>
          import("@/views/dashboard/modules/Ai/dashboard/aiDashboardIndex"),
      },
      {
        name: "Ai Total Report",
        path: "aiStudFullRepo",
        component: () =>
          import("@/views/dashboard/modules/Ai/report/aiStudentFull"),
      },
      {
        name: "Ai Total Report",
        path: "totalSessionsReport",
        component: () =>
          import("@/views/dashboard/modules/Ai/report/totalSessionsReport"),
      },
      {
        name: "Ai Total Report",
        path: "teachersStatisticalReport",
        component: () =>
          import("@/views/dashboard/modules/Ai/report/teachersStatisticalReport"),
      },
      {
        name: "Ai Total Report",
        path: "studentStatisticalReport",
        component: () =>
          import("@/views/dashboard/modules/Ai/report/studentStatisticalReport"),
      },
      {
        name: "Student Dashboard",
        path: "aiStudDash",
        component: () =>
          import("@/views/dashboard/modules/Ai/dashboard/studentDashboard"),
      },
      {
        name: "createInvoiceFromOrder",
        path: "createInvoiceFromOrder/:id",
        component: () =>
          import("@/views/dashboard/modules/workflow/createInvoiceFromOrder.vue"),
      },
      {
        name: "newRequestTemplate",
        path: "createRequestFromTemplate/student/:id",
        component: () =>
          import("@/views/dashboard/modules/workflow/createRequestFromTemplate.vue"),
      },
      {
        name: "receipt",
        path: "receipt/:id",
        component: () =>
          import("@/views/dashboard/modules/workflow/receipt.vue"),
      },
      {
        name: "delivery",
        path: "delivery/:id",
        component: () =>
          import("@/views/dashboard/modules/workflow/delivery.vue"),
      },
      {
        name: "invoiceWarehouse",
        path: "invoiceWarehouse/:id",
        component: () =>
          import("@/views/dashboard/modules/workflow/invoiceWarehouse.vue"),
      },
      {
        name: "Purchase Management",
        path: "purchaseManagement",
        component: () =>
          import("@/views/dashboard/modules/workflow/purchaseManagement.vue"),
      },
      {
        name: "Sales Management",
        path: "salesManagement",
        component: () =>
          import("@/views/dashboard/modules/workflow/salesManagement.vue"),
      },
      {
        name: "Warehouse",
        path: "warehouse",
        component: () =>
          import("@/views/dashboard/modules/workflow/inventory.vue"),
      },
      {
        name: "Deliveried Order",
        path: "deliveriedOrder",
        component: () =>
          import("@/views/dashboard/modules/workflow/deliveriedOrder.vue"),
      },
      {
        name: "طلب جديد",
        path: "newRequest",
        component: () =>
          import("@/views/dashboard/modules/workflow/newRequest.vue"),
      },
      {
        name: "تعديل فاتورة",
        path: "edit-request/:id",
        component: () =>
          import("@/views/dashboard/modules/workflow/editRequest.vue"),
      },
      {
        name: "ادارة الفواتير",
        path: "bill-management",
        component: () =>
          import("@/views/dashboard/modules/bill-management/index"),
      },
      {
        name: "الفواتير",
        path: "invoice-list",
        component: () =>
          import("@/views/dashboard/modules/invoices/InvoiceList.vue"),
      },
      {
        name: "الفواتير",
        path: "new-invoice",
        component: () =>
          import("@/views/dashboard/modules/invoices/newInvoice.vue"),
      },
      {
        name: "invoiceTemplate",
        path: "invoice-Template",
        component: () =>
          import("@/views/dashboard/modules/invoices/invoiceTemplate/invoiceTemplate.vue"),
      },
      {
        name: "newInvoiceTemplate",
        path: "newinvoiceTemplate/:id",
        component: () =>
          import("@/views/dashboard/modules/invoices/invoiceTemplate/newinvoiceTemplate.vue"),
      },
      {
        name: "newInvoiceTemplate",
        path: "createInvoiceFromTemplate/:id",
        component: () =>
          import("@/views/dashboard/modules/invoices/invoiceTemplate/createInvoiceFromTemplate.vue"),
      },
      {
        name: "newInvoiceTemplate",
        path: "createInvoiceFromTemplate/student/:sid",
        component: () =>
          import("@/views/dashboard/modules/invoices/invoiceTemplate/createInvoiceFromTemplate.vue"),
      },
      {
        name: "Trial_Balance",
        path: "trialBalance",
        component: () =>
          import("@/views/dashboard/modules/reports/trialBalance.vue"),
      },
      {
        name: "groupByTrialBalance",
        path: "groupByTrialBalance",
        component: () =>
          import("@/views/dashboard/modules/reports/groupByTrialBalance.vue"),
      },
      {
        name: "Ledger",
        path: "ledger",
        component: () =>
          import("@/views/dashboard/modules/reports/ledger.vue"),
      },
      {
        name: "Cost Centers Report",
        path: "costCentersReport",
        component: () =>
          import("@/views/dashboard/modules/reports/costCenters.vue"),
      },
      {
        name: "Balance",
        path: "balance",
        component: () =>
          import("@/views/dashboard/modules/reports/balance.vue"),
      },
      {
        name: "Taxes Report",
        path: "taxesReport",
        component: () =>
          import("@/views/dashboard/modules/reports/taxesReport.vue"),
      },
      {
        name: "Digital Wallet Report",
        path: "digitalWalletReport",
        component: () =>
          import("@/views/dashboard/modules/reports/digitalWalletReport.vue"),
      },
      {
        name: "MaterialMovement",
        path: "materialMovement",
        component: () =>
          import("@/views/dashboard/modules/inventories/reports/materialMovement.vue"),
      },
      {
        name: "Orders Report",
        path: "ordersReport",
        component: () =>
          import("@/views/dashboard/modules/inventories/reports/ordersReports.vue"),
      },
      {
        name: "MaterialInventory",
        path: "materialInventory",
        component: () =>
          import("@/views/dashboard/modules/inventories/reports/materialInventory.vue"),
      },
      {
        name: "MaterialSeparation",
        path: "materialSeparation",
        component: () =>
          import("@/views/dashboard/modules/inventories/reports/materialSeparation.vue"),
      },
      {
        name: "SalaryReports",
        path: "HR/salaryReports",
        component: () =>
          import("@/views/dashboard/modules/HR/reports/salaryReports.vue"),
      },
      {
        name: "TotalLoanReport",
        path: "HR/totalLoanReport",
        component: () =>
          import("@/views/dashboard/modules/HR/reports/totalLoanReport.vue"),
      },
      {
        name: "detailsLoan",
        path: "HR/detailsLoan/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/reports/detailsLoan.vue"),
      },
      {
        name: "TimeReport",
        path: "HR/timeReport",
        component: () =>
          import("@/views/dashboard/modules/HR/reports/timeReport.vue"),
      },
      {
        name: "invoiceTemplateForm",
        path: "invoiceTemplateForm",
        component: () =>
          import("@/views/dashboard/modules/invoices/invoiceTemplate/invoiceTemplateForm.vue"),
      },
      {
        name: "editInvoiceTemplate",
        path: "editInvoiceTemplate/:id",
        component: () =>
          import("@/views/dashboard/modules/invoices/invoiceTemplate/editInvoiceTemplate.vue"),
      },
      {
        name: "تعديل فاتورة",
        path: "edit-invoice/:id",
        component: () =>
          import("@/views/dashboard/modules/invoices/editInvoice.vue"),
      },
      {
        name: "أنواع المواد",
        path: "/material-types",
        component: () =>
          import("@/views/dashboard/modules/material-types/index.vue"),
      },
      {
        name: "الفواتير",
        path: "new-transportation-invoice",
        component: () =>
          import("@/views/dashboard/modules/invoices/newTransportationInvoice.vue"),
      },
      {
        name: "الفواتير",
        path: "edit-transportation-invoice/:id",
        component: () =>
          import("@/views/dashboard/modules/invoices/editTransportationInvoice.vue"),
      },
      {
        name: "شجرة المواد",
        path: "material-tree",
        component: () =>
          import("@/views/dashboard/modules/material-tree/index"),
      },
      {
        name: "المستودعات",
        path: "inventories",
        component: () =>
          import("@/views/dashboard/modules/inventories/index"),
      },
      {
        name: "التقارير المحاسبية",
        path: "inventoryReports",
        component: () =>
          import(
            "@/views/dashboard/modules/inventories/inventoryReportsIndex"
          ),
      },
      {
        name: "المحاسبة",
        path: "accounting",
        component: () =>
          import("@/views/dashboard/modules/accounting/accountingIndex"),
      },
      {
        name: "Sub Account",
        path: "subAccount",
        component: () =>
          import("@/views/dashboard/modules/accounting/subAccountIndex"),
      },
      {
        name: "التدوير",
        path: "accountingRotation",
        component: () =>
          import(
            "@/views/dashboard/modules/accounting-entry/acountingRotation"
          ),
      },
      {
        name: "مراكز الكلفة",
        path: "costCenters",
        component: () => import("@/views/dashboard/modules/cost-centers/Index"),
      },
      {
        name: "القيود",
        path: "accountingEntry",
        component: () =>
          import("@/views/dashboard/modules/accounting-entry/accountingEntry"),
      },
      {
        name: "القيود",
        path: "accountingEntryId/:id",
        component: () =>
          import("@/views/dashboard/modules/accounting-entry/accountingEntry"),
      },
      {
        name: "إضافة قيد",
        path: "createAccountingEntry",
        component: () =>
          import(
            "@/views/dashboard/modules/accounting-entry/createAccountingEntry"
          ),
      },
      {
        name: "أمناء الصناديق",
        path: "cashierBox",
        component: () => import("@/views/dashboard/modules/cashier-box/Index"),
      },
      {
        name: "إضافة قالب",
        path: "createTemplateEntry",
        component: () =>
          import(
            "@/views/dashboard/modules/accountingTemplates/createTemplateEntry"
          ),
      },
      {
        name: "تعديل قالب",
        path: "editTemplateEntry/:templateId",
        component: () =>
          import(
            "@/views/dashboard/modules/accountingTemplates/editTemplateEntry"
          ),
      },
      {
        name: "تعديل قيد",
        path: "editAccountingEntry/:id",
        component: () =>
          import(
            "@/views/dashboard/modules/accounting-entry/editAccountingEntry"
          ),
      },
      {
        name: "قوالب المحاسبة",
        path: "accountingTemplates",
        component: () =>
          import(
            "@/views/dashboard/modules/accountingTemplates/accountingTemplates"
          ),
      },
      {
        name: "إضافة قيد من قالب",
        path: "createEntryFromTemplate/:templateId",
        component: () =>
          import(
            "@/views/dashboard/modules/accountingTemplates/displayTemplate"
          ),
      },
      {
        name: "كشف حساب",
        path: "accountStatement/:statementId",
        component: () =>
          import("@/views/dashboard/modules/accounting/accountStatement"),
      },
      {
        name: "كشف حساب",
        path: "accountStatement/:statementId/:subAccId",
        component: () =>
          import("@/views/dashboard/modules/accounting/accountStatementUser"),
      },
      {
        name: "الصندوق",
        path: "cashierBoxStatement/:statementId",
        component: () =>
          import("@/views/dashboard/modules/accounting/cashierBoxStatement"),
      },
      {
        name: "أوامر المحاسب",
        path: "financialOrder",
        component: () =>
          import(
            "@/views/dashboard/modules/financial-order/financialOrderIndex"
          ),
      },
      {
        name: "أوامر المحاسب",
        path: "financialOrderParents",
        component: () =>
          import(
            "@/views/dashboard/modules/financial-order-parents/financialOrderParentsIndex"
          ),
      },
      {
        name: "إدارة المستندات",
        path: "document-management",
        component: () =>
          import("@/views/dashboard/modules/document-management/index"),
      },
      {
        name: "المستندات",
        path: "documents-list",
        component: () =>
          import("@/views/dashboard/modules/documents/documentsList.vue"),
      },
      {
        name: "مستند جديد",
        path: "new-document",
        component: () =>
          import("@/views/dashboard/modules/documents/newDocument.vue"),
      },
      {
        name: "تعديل مستند",
        path: "edit-document/:id",
        component: () =>
          import("@/views/dashboard/modules/documents/editDocument.vue"),
      },
      {
        name: "pay installments",
        path: "payInstallments",
        component: () =>
          import(
            "@/views/dashboard/modules/pay-installments/payInstallmentsIndex"
          ),
      },
      {
        name: "New Pay Installments",
        path: "newPayInstallment",
        component: () =>
          import(
            "@/views/dashboard/modules/pay-installments/newPayInstallment"
          ),
      },
      {
        name: "Display Pay Installments",
        path: "displaypayments/:id",
        component: () =>
          import(
            "@/views/dashboard/modules/pay-installments/displaypayments"
          ),
      },
      {
        name: "تقارير",
        path: "reports",
        component: () =>
          import(
            "@/views/dashboard/modules/reports/reportsIndex"
          ),
      },
      {
        name: "أوامر الصندوق",
        path: "cashierFinancialOrder",
        component: () =>
          import(
            "@/views/dashboard/modules/financial-order/cashierFinancialOrderIndex"
          ),
      },
      {
        name: "الصناديق المسؤول عنها",
        path: "myBoxes",
        component: () => import("@/views/dashboard/modules/accounting/myBoxes"),
      },

      {
        name: "الأدوار",
        path: "roles",
        component: () => import("@/views/dashboard/modules/roles/rolesIndex"),
      },
      {
        name: "السجل العام",
        path: "students",
        component: () =>
          import("@/views/dashboard/modules/students/studentsIndex"),
      },
      {
        name: "الجلاء المدرسي",
        path: "graduation",
        component: () =>
          import("@/views/dashboard/modules/graduation/graduationIndex"),
      },
      {
        name: "المنطقة المؤقتة",
        path: "temporary-area",
        component: () =>
          import("@/views/dashboard/modules/temporary-area/studentsIndex"),
      },
      {
        name: "edit Student Temporary",
        path: "editStudentTemporary/:id",
        component: () =>
          import("@/views/dashboard/modules/temporary-area/editStudent"),
      },
      {
        name: "تقارير الطلاب",
        path: "students/reports",
        component: () => import("@/views/dashboard/modules/students/reports/index.vue"),
      },
      {
        name: "Absent Report",
        path: "students/absentReportsIndex",
        component: () =>
          import("@/views/dashboard/modules/students/reports/absentReportsIndex.vue"),
      },
      {
        name: "Results Report",
        path: "students/resultsReport",
        component: () =>
          import("@/views/dashboard/modules/students/reports/resultsReport.vue"),
      },
      {
        name: "Tests Report",
        path: "students/testsReport",
        component: () =>
          import("@/views/dashboard/modules/students/reports/testsReport.vue"),
      },
      {
        name: "الآباء",
        path: "parents",
        component: () =>
          import("@/views/dashboard/modules/users/parents/parentsIndex"),
      },
      {
        name: "المعلمون",
        path: "teachers",
        component: () =>
          import("@/views/dashboard/modules/teachers/teachersIndex"),
      },
      {
        name: "Educational Supervisor",
        path: "educationalSupervisors",
        component: () =>
          import("@/views/dashboard/modules/educational-supervisor/educationalSupervisorIndex"),
      },
      {
        name: "الحصص الأسبوعية",
        path: "weekly-classes",
        component: () =>
          import("@/views/dashboard/modules/teachers/weeklyClasses"),
      },
      {
        name: " الدروس",
        path: "teacher-lessons",
        component: () =>
          import("@/views/dashboard/modules/teachers/teacherLessons"),
      },
      {
        name: " استعراض درس",
        path: "view-teacher-lesson/:id",
        component: () =>
          import("@/views/dashboard/modules/teachers/viewTeacherLesson"),
      },
      {
        name: "المسؤولين",
        path: "admins",
        component: () =>
          import("@/views/dashboard/modules/users/admins/adminsIndex"),
      },
      {
        // complete the route name from the routeNameAddition in store
        name: "إضافة",
        path: "addUser/:userType",
        component: () => import("@/views/dashboard/modules/users/addUser"),
      },
      {
        // complete the route name from the routeNameAddition in store
        name: "تعديل",
        path: "editUser/:userType/:id",
        component: () => import("@/views/dashboard/modules/users/editUser"),
      },
      {
        // complete the route name from the routeNameAddition in store
        name: "Show User",
        path: "showUser/:userType/:id",
        component: () => import("@/views/dashboard/modules/users/showUser"),
      },
      {
        name: "إضافة طالب",
        path: "createStudent",
        component: () =>
          import("@/views/dashboard/modules/students/createStudent"),
      },
      {
        name: "متغيرات طالب",
        path: "variablesStudent",
        component: () =>
          import("@/views/dashboard/modules/students/variables"),
      },
      {
        name: "إضافة طالب للمنطقة المؤقتة",
        path: "create-temp-student",
        component: () =>
          import("@/views/dashboard/modules/temporary-area/createStudent"),
      },
      {
        name: "temp-student-register",
        meta: { isPublic: true } ,
        path: "create-temp-student-register",
        component: () =>
          import("@/views/dashboard/modules/temporary-area/createStudentRegister"),
      },
      {
        name: "تعديل طالب",
        path: "editStudent/:id",
        component: () =>
          import("@/views/dashboard/modules/students/editStudent"),
      },
      {
        name: "استعراض مسؤولية",
        path: "view-responsibility/:id",
        component: () =>
          import("@/views/dashboard/modules/teachers/viewResponsibility"),
      },
      {
        name: "استعراض واجب",
        path: "view-homework/:id",
        component: () =>
          import("@/views/dashboard/modules/teachers/viewHomework"),
      },

      {
        name: "استعراض طالب",
        path: "registrationRecord/:id/statement/:statementId",
        component: () =>
          import(
            "@/views/dashboard/modules/students/registrationRecord/registrationRecord"
          ),
      },
      {
        name: "Add Parent",
        path: "addNewParent/:studentId",
        component: () =>
          import(
            "@/views/dashboard/modules/students/registrationRecord/components/addParents"
          ),
      },
      {
        name: "Add Parent",
        path: "editNewParent/:id",
        component: () =>
          import(
            "@/views/dashboard/modules/students/registrationRecord/components/editParents"
          ),
      },
      {
        name: "استعراض معلم",
        path: "teacher/:id",
        component: () =>
          import("@/views/dashboard/modules/teachers/viewTeacher"),
      },
      {
        name: " الواجبات",
        path: "homeworks",
        component: () => import("@/views/dashboard/modules/teachers/homeworks"),
      },
      {
        name: " الملحقات",
        path: "attachments",
        component: () =>
          import("@/views/dashboard/modules/teachers/attachments"),
      },
      {
        name: "تقارير القسم التعليمي",
        path: "reportTechers",
        component: () =>
          import("@/views/dashboard/modules/teachers/reportsIndex"),
      },
      {
        name: "تقرير الدروس",
        path: "lessonReport",
        component: () =>
          import("@/views/dashboard/modules/teachers/lessonReport.vue"),
      },
      {
        name: "إضافة دور",
        path: "createRole",
        component: () => import("@/views/dashboard/modules/roles/createRole"),
      },
      {
        name: "تعديل دور",
        path: "editRole/:id",
        component: () => import("@/views/dashboard/modules/roles/editRoleIndex"),
      },
      {
        name: "Questions Lms",
        path: "questionsLms",
        component: () =>
          import("@/views/dashboard/modules/lms/questions/questionsLmsIndex.vue"),
      },
      {
        name: "Quizzes Lms",
        path: "quizzesLms",
        component: () =>
          import("@/views/dashboard/modules/lms/quizzes/quizzesIndex.vue"),
      },
      {
        name: "Add Quiz Lms",
        path: "addQuizLms",
        component: () =>
          import("@/views/dashboard/modules/lms/quizzes/addQuizLms.vue"),
      },
      {
        name: "Edit Quiz Lms",
        path: "editQuizLms/:id",
        component: () =>
          import("@/views/dashboard/modules/lms/quizzes/editQuizLms.vue"),
      },
      {
        name: "Questions Lms",
        path: "lmsReports",
        component: () =>
          import("@/views/dashboard/modules/lms/reports/lmsReportIndex.vue"),
      },
      {
        name: "Quizzes Results",
        path: "quizzesResults",
        component: () =>
          import("@/views/dashboard/modules/lms/reports/resultLmsQuizReport.vue"),
      },
      {
        name: "Results Details Stu",
        path: "resultsDetailsStu/:id",
        component: () =>
          import("@/views/dashboard/modules/lms/reports/resultDetailsLmsStu.vue"),
      },
      {
        name: "Results Details LMS",
        path: "resultsDetails",
        component: () =>
          import("@/views/dashboard/modules/lms/reports/resultDetailsLmsReport.vue"),
      },
      {
        name: "الأساسيات",
        path: "mainViews",
        component: () =>
          import("@/views/dashboard/modules/mainViews/mainViews"),
      },
      {
        name: "العام الدراسي",
        path: "educationalYear",
        component: () =>
          import(
            "@/views/dashboard/modules/mainViews/educational-year/educationalYear"
          ),
      },
      {
        name: "الفصل الدراسي",
        path: "terms",
        component: () =>
          import("@/views/dashboard/modules/mainViews/term/termIndex"),
      },
      {
        name: "نوع الفصل",
        path: "termTypes",
        component: () =>
          import("@/views/dashboard/modules/mainViews/term-type/termTypeIndex"),
      },
      {
        name: "الصفوف",
        path: "grades",
        component: () =>
          import("@/views/dashboard/modules/mainViews/grade/gradeIndex"),
      },
      {
        name: "معلومات صف",
        path: "grade/info/:gradeId",
        component: () =>
          import(
            "@/views/dashboard/modules/mainViews/grade/gradeInfo/gradeInfoView"
          ),
      },
      {
        name: "نوع الصفوف",
        path: "gradeTypes",
        component: () =>
          import(
            "@/views/dashboard/modules/mainViews/grade-type/gradeTypeIndex"
          ),
      },
      {
        name: "الطوابق",
        path: "floors",
        component: () =>
          import("@/views/dashboard/modules/mainViews/floor/floorIndex"),
      },
      {
        name: "الغرف",
        path: "rooms",
        component: () =>
          import("@/views/dashboard/modules/mainViews/room/roomIndex"),
      },
      {
        name: "المواد",
        path: "subjects",
        component: () =>
          import("@/views/dashboard/modules/mainViews/subjects/subjectIndex"),
      },
      {
        name: "Diseases",
        path: "diseaseMain",
        component: () =>
          import(
            "@/views/dashboard/modules/mainViews/disease/diseaseMain"
          ),
      },
      {
        name: "Consents",
        path: "consentMain",
        component: () =>
          import(
            "@/views/dashboard/modules/mainViews/consent/consentMain"
          ),
      },
      {
        name: "بنية النتائج",
        path: "gradeStructure",
        component: () =>
          import("@/views/dashboard/modules/mainViews/gradeStructure/gradeStructureIndex"),
      },
      {
        name: "بنية العلامات",
        path: "resultsStructure",
        component: () =>
          import("@/views/dashboard/modules/mainViews/gradeStructure/resultsStructure"),
      },
      {
        name: "بنية الجلاء",
        path: "resultsTemplate",
        component: () =>
          import("@/views/dashboard/modules/mainViews/gradeStructure/resultsTemplate"),
      },
      {
        name: "إضافة نموذج جلاء",
        path: "addTemp",
        component: () =>
          import("@/views/dashboard/modules/mainViews/gradeStructure/addTemp"),
      },
      {
        name: "تعديل نموذج جلاء",
        path: "editTemp/:id",
        component: () =>
          import("@/views/dashboard/modules/mainViews/gradeStructure/editTemp"),
      },
      {
        name: "النسخ الاحتياطي",
        path: "backup",
        component: () =>
          import("@/views/dashboard/modules/mainViews/backup/backupIndex"),
      },
      {
        name: "النسخ الاحتياطي",
        path: "log",
        component: () =>
          import("@/views/dashboard/modules/mainViews/log/logIndex"),
      },
      {
        name: "المتغيرات",
        path: "variables",
        component: () =>
          import("@/views/dashboard/modules/mainViews/variable/variablesIndex"),
      },
      {
        name: "Taxes",
        path: "taxes",
        component: () =>
          import("@/views/dashboard/modules/mainViews/taxes/taxesIndex"),
      },
      {
        name: "settings",
        path: "settings",
        component: () =>
          import("@/views/dashboard/modules/mainViews/settings/settingsIndex"),
      },
      {
        name: "Taxes Index",
        path: "taxesIndex",
        component: () =>
          import("@/views/dashboard/modules/mainViews/taxes/taxesIndexAdd"),
      },
      {
        name: "Taxes Type",
        path: "taxesTypeIndex",
        component: () =>
          import("@/views/dashboard/modules/mainViews/taxes/taxesTypeIndex"),
      },
      // {
      //   name: "Taxes",
      //   path: "taxes",
      //   component: () =>
      //     import("@/views/dashboard/modules/mainViews/taxes/taxesIndex"),
      // },
      {
        name: "استعراض مادة",
        path: "subject/:id",
        component: () =>
          import("@/views/dashboard/modules/mainViews/subjects/viewSubject"),
      },
      {
        name: "بنك أسئلة مادة",
        path: "lmsSubject/:id",
        component: () =>
          import("@/views/dashboard/modules/mainViews/subjects/lms/lmsSubject"),
      },
      {
        name: "التسجيل في السنة الجديدة",
        path: "newYear/:id",
        component: () =>
          import(
            "@/views/dashboard/modules/mainViews/education-class/eduClassInfo/newYear"
          ),
      },
      {
        name: "الشعب",
        path: "educationClass",
        component: () =>
          import(
            "@/views/dashboard/modules/mainViews/education-class/educationClassIndex"
          ),
      },
      {
        name: "معلومات شعبة",
        path: "edu-class/info/:eduClassId",
        component: () =>
          import(
            "@/views/dashboard/modules/mainViews/education-class/eduClassInfo/eduClassInfoView"
          ),
      },
      {
        name: "البوفيه",
        path: "buffet",
        component: () => import("@/views/dashboard/modules/buffet/buffet"),
      },
      {
        name: "المنتجات",
        path: "buffetProducts",
        component: () =>
          import("@/views/dashboard/modules/buffet/product/productsIndex"),
      },
      {
        name: "الوجبات",
        path: "Meal",
        component: () => import("@/views/dashboard/meal/mealIndex"),
      },
      {
        name: "الوجبات التي تم طلبها",
        path: "orderedMeals",
        component: () =>
          import("@/views/dashboard/orderedMeal/orderedMealIndex"),
      },
      {
        name: "فواتير الطلبات",
        path: "mealManufacturing",
        component: () =>
          import("@/views/dashboard/meal/mealManufacturing"),
      },
      {
        name: "Food Categories",
        path: "foodCategories",
        component: () =>
          import("@/views/dashboard/meal/foodCategories"),
      },
      // {
      //   name: "فواتير الطلبات",
      //   path: "ordersInvoices",
      //   component: () =>
      //     import("@/views/dashboard/orderedMeal/ordersInvoices"),
      // },
      {
        name: "Meal Reports",
        path: "meal/reports",
        component: () => import("@/views/dashboard/meal/reports/index.vue"),
      },
      {
        name: "Resturant Report",
        path: "meal/resturantReport",
        component: () =>
          import("@/views/dashboard/meal/reports/resturantReport.vue"),
      },
      {
        name: "Resturant Report",
        path: "meal/buffetCardReport",
        component: () =>
          import("@/views/dashboard/meal/reports/buffetCardReport.vue"),
      },
      {
        name: "Resturant Report",
        path: "meal/buffetCardReport/:id",
        component: () =>
          import("@/views/dashboard/meal/reports/buffetCardReport.vue"),
      },
      {
        name: "وجبات الطلاب",
        path: "studentsMeal",
        component: () =>
          import("@/views/dashboard/studentMeal/studentMealIndex"),
      },
      {
        name: "السائقين",
        path: "drivers",
        component: () => import("@/views/dashboard/drivers/driversIndex"),
      },
      {
        name: "رحلات السائقين",
        path: "driversRoutes",
        component: () => import("@/views/dashboard/busRoute/busRouteIndex"),
      },
      {
        name: "تقارير خطوط السير",
        path: "drivers/reports",
        component: () => import("@/views/dashboard/drivers/reports/index.vue"),
      },
      {
        name: "تقرير الرحلات",
        path: "drivers/tripsReport",
        component: () => import("@/views/dashboard/drivers/reports/tripsReport.vue"),
      },
      {
        name: "تقرير تفاصيل الرحلات",
        path: "drivers/detailsTripsReport",
        component: () => import("@/views/dashboard/drivers/reports/detailsTripsReport.vue"),
      },
      {
        name: "الطلاب ضمن الرحلة",
        path: "routeInfo/:routeId",
        component: () => import("@/views/dashboard/busRoute/studentsRoute"),
      },
      {
        name: "عرض القيود",
        path: "showRestrictions",
        component: () => import("@/views/dashboard/busRoute/showRestrictions"),
      },
      {
        name: "displayRestriction",
        path: "displayRestriction/:id",
        component: () => import("@/views/dashboard/busRoute/displayRestriction"),
      },
      {
        name: "الموارد البشرية التأسيس",
        path: "HR/initiate",
        component: () =>
          import("@/views/dashboard/modules/HR/initiate/InitiateHome"),
      },
      {
        name: "جدول الدوام",
        path: "HR/time-attendance",
        component: () => import("@/views/dashboard/modules/HR/time/index.vue"),
      },
      {
        name: "الاجازات",
        path: "HR/vacations",
        component: () => import("@/views/dashboard/modules/HR/vacations/index.vue"),
      },
      {
        name: "المتغيرات",
        path: "HR/variations",
        component: () => import("@/views/dashboard/modules/HR/variations/index.vue"),
      },
      {
        name: "السلف",
        path: "HR/loans",
        component: () => import("@/views/dashboard/modules/HR/loans/index.vue"),
      },
      {
        name: "احتساب الرواتب",
        path: "HR/calculations",
        component: () => import("@/views/dashboard/modules/HR/calculations/index.vue"),
      },
      {
        name: "تسديد الرواتب",
        path: "HR/payments",
        component: () => import("@/views/dashboard/modules/HR/payments/index.vue"),
      },
      {
        name: "تقارير الموارد البشرية",
        path: "HR/reports",
        component: () => import("@/views/dashboard/modules/HR/reports/index.vue"),
      },
      {
        name: "السجل العام للموظفين",
        path: "HR/PublicRecord",
        component: () => import("@/views/dashboard/modules/HR/PublicRecord"),
      },
      {
        name: "الورديات",
        path: "Shifts/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/initiate/ShiftsView"),
      },
      {
        name: "قواعد الراتب",
        path: "SalaryRules/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/initiate/SalaryRulesView"),
      },
      {
        name: "سلف الراتب",
        path: "/HR/SalaryLoan/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/initiate/SalaryLoan"),
      },
      {
        name: "متغيرات الراتب",
        path: "SalaryVariants/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/initiate/SalaryVariants"),
      },
      {
        name: "الاجازات",
        path: "vacations/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/initiate/VacationsView"),
      },
      {
        name: "مجموعات الموظفين",
        path: "employee_groupes/edit/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/initiate/EmployeeGroupesEdit"),
      },
      {
        name: "مجموعاتت الموظفين",
        path: "employee_groupes/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/initiate/EmployeeGroupes"),
      },
      {
        name: "عقد العمل",
        path: "work_contract/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/initiate/WorkContractView"),
      },

      {
        name: "أضافة موظف",
        path: "/HR/CreateEmpolyee",
        component: () => import("@/views/dashboard/modules/HR/CreateEmployee"),
      },
      {
        name: "تعديل موظف",
        path: "/HR/EditEmployee/:id",
        component: () =>
          import("@/views/dashboard/modules/HR/EditEmployee"),
      },
      {
        name: "مهامي",
        path: "myTasks",
        component: () =>
          import("@/views/dashboard/modules/messages/myTasks"),
      },
      {
        name: "المهام المرسلة",
        path: "tasksSent",
        component: () =>
          import("@/views/dashboard/modules/messages/tasksSent"),
      },
      {
        name: "جميع المهام",
        path: "allTasks",
        component: () =>
          import("@/views/dashboard/modules/messages/allTasks"),
      },
      {
        name: "البريد الوارد",
        path: "incomingMail",
        component: () =>
          import("@/views/dashboard/modules/messages/incomingMail"),
      },
      {
        name: "الرسائل المرسلة",
        path: "messagesSent",
        component: () =>
          import("@/views/dashboard/modules/messages/messagesSent"),
      },
      {
        name: "جميع الرسائل",
        path: "allMessages",
        component: () =>
          import("@/views/dashboard/modules/messages/allMessages"),
      },
      {
        name: "عرض الرسائل",
        path: "showMessage/:id",
        component: () =>
          import("@/views/dashboard/modules/messages/showMessage"),
      },
    ],
  },
  
];

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
