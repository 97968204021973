import axios from 'axios'
import i18n from '@/i18n';
import {confirmDialog} from "@/helpers/sweetalert";

const state = {
  options: {},
  serverTotal: 1,
  page: 1,
  data: [],
  url: '/hr/document',
  postUrl: 'hr/document/bonus',
  loading: false,
  
}
const getters = {
  data(state) {
    return state.data
  },
  headers(state) {
    return [...state.headers, {text: '', value: 'actions'}]
  }
}
const mutations = {}
const actions = {
  async fetch({commit}, queryParams) {
    try {
      state.loading = true;
      const filterTemp = {};
        Object.keys(queryParams).forEach((key) => {
          if (queryParams[key]) filterTemp[key] = queryParams[key];
        });
        let params = {...filterTemp}
      const response = await axios.get(`${state.url}?type=bonus`, 
        {params}
      );
      state.data = response.data.documents;
      state.serverTotal = response.data.meta.total;
      state.page = response.data.meta.current_page;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async store({commit, state, dispatch}, data) {
    try {
      state.loading = true
      const res = await axios.post(`${state.postUrl}`, data);
      dispatch('fetch')
      return res;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async update({commit, state, dispatch}, data) {
    try {
      state.loading = true
      const res = await axios.put(`${state.url}/${data.id}`, data);
      dispatch('fetch')
      return res;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
