<template>
  <div>
    <v-app>
      <router-view></router-view>
    </v-app>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
    };
  },
  created() {
  },
};
</script>
<style src="./globalCss/global.css"></style>
