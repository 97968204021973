import axios from "axios";
// import Vue from 'vue'
import store from "../store/store";
import Notifications from "../helpers/toast-notifications";
import i18n from "../i18n";
axios.defaults.baseURL = store.state.serverDomain;
axios.interceptors.request.use((request) => {
  //
  return request;
});

axios.interceptors.response.use(
  (response) => {
    //
    return response;
  },
  (error) => {
    if (error.message === "Network Error") {
      Notifications(
        i18n.t("internet Error"),
        { timeout: 0, rtl: true },
        "error"
      );
    }
    const status = error.response.status;
    const status_text = error.response.statusText;
    const message = error.response.data.message;
    let errors = error.response.data.errors;
    if (status == 400 || status == 404 || status == 401 || status == 403) {
      Notifications(i18n.t(message), {}, "error");
    }
    if (status == 422) {
      if (!errors) {
       
        Notifications(message, {}, "error");
      }
      console.log("errors", errors);
      for (let key in errors) {
        Notifications(errors[key][0], {}, "error");
        return Promise.reject(error);
      }
    }
    if (status == 500) {
      Notifications(
        i18n.t("Something went wrong please try again"),
        {},
        "error"
      );
    }
    return Promise.reject(error);
  }
);

export default {};
