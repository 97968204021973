import axios from "axios";
import { ValidationError } from '../errors/Validation-Error';

export class Request {
    constructor(method, endpoint, data, params, token) {
        this.method = method;
        this.endpoint = endpoint;
        this.data = data;
        this.params = params;
        this.token = token;
    }

    async send() {

        try {
            let response = await axios({
                method: this.method,
                url: this.endpoint,
                data: this.data,
                params: (typeof this.params == 'object') ? this.params : undefined,
                headers: { 'Authorization': `Bearer ${this.token}` }
            })
            return response.data
        } catch (error) {
            if (error.toJSON().message === 'Network Error') {
                throw new ValidationError('Internet Error', "INTERNET ERROR");
            }
            else if (error.response.data.status === 'fail') {
                throw new ValidationError(this.errorMapper(error.response.data.error), "USER ERROR");
            }
        }
    }
    errorMapper(type) {
        const error = new Map([
            ['No internet', 'Internet Error'],
            ['ERR_TIMED_OUT', 'Internet Error'],
            ['ERR_INTERNET_DISCONNECTED', 'Internet Error'],
            ['INVALID_CREDENTIALS', 'Error in Password'],
            ['PASSWORD_INVALID', 'invalid_password'],
            ['EMAIL_ALREADY_EXISTS', 'email_already_exists'],
            ['USERNAME_ALREADY_EXISTS', 'The User Name is  Used Already'],
            ['VERIFY_TOKEN_INVALID', 'Invalid Verify Code'],
            ['RESET_CODE_INVALID', 'Invalid Reset Code'],
            ['RESET_CODE_INVALID', 'invalid_reset_code'],
            ['RESOURCE_NOT_FOUND', 'Error in Email'],
            ['NOT_VERIFIED', 'Verify Please'],
            ['UNACCEPTABLE_OPERATION', 'Error in Verify Code'],
            ['RESOURCE_ALREADY_EXISTS', 'Email already Exist']

        ])
        return error.get(type)
    }
}
