import axios from 'axios'
const state = {
  options: {},
  page: 1,
  serverTotal: 1,
  data: [],
  url: '/hr/document',
  postUrl: '/hr/document/leave',
  loading: false,
}
const getters = {
  data(state) {
    return state.data
  },
}
const mutations = {}
const actions = {
  async fetch({commit}, queryParams) {
    try {
      state.loading = true;
      const filterTemp = {};
        Object.keys(queryParams).forEach((key) => {
          if (queryParams[key]) filterTemp[key] = queryParams[key];
        });
        let params = {...filterTemp}
      const response = await axios.get(`${state.url}?type=leave`, 
        {params}
      );
      state.data = response.data.documents;
      state.serverTotal = response.data.meta.total;
      state.page = response.data.meta.page;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async store({commit, state, dispatch}, data) {
    try {
      state.loading = true
      const res = await axios.post(`${state.postUrl}`, data);
      dispatch('fetch')
      return res;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
  async update({commit, state, dispatch}, data) {
    try {
      state.loading = true
      const res = await axios.put(`${state.postUrl}/${data.id}`, data);
      dispatch('fetch')
      return res;
    } catch (err) {
      console.log('err', err);
    } finally {
      state.loading = false
    }
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
